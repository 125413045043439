import React from 'react';
import Layout from '../components/Layout/index';
import ServicePage from '../components/pages/services/index';

export default () => (
  <Layout>
    <ServicePage selectedTab="websites">
      <p>
        At Oops Typo, we pride ourselves in our ability to link both design and technology together to create a digital
        experience like no other. Our digital scope ranges from web design to complex applications. We believe that
        technology can only be fully utilized to its full potential when paired with well-crafted design.
      </p>
    </ServicePage>
  </Layout>
);
